
import EquipmentTypesSelect from "@/components/catalogs-select/EquipmentTypesSelect.vue";
import PackagingTypeSelect from "@/components/catalogs-select/PackagingTypeSelect.vue";
import MerchandiseTypeSelect from "@/components/catalogs-select/MerchandiseTypeSelect.vue";
import EquipmentsSelect from "@/components/catalogs-select/EquipmentsSelect.vue";
import { Field } from "vee-validate";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

export default {
  name: "AddFCLLine",
  props: {
    forwarder: {
      type: Boolean,
      default: false,
    },
    equipment: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  components: {
    Field,
    MerchandiseTypeSelect,
    EquipmentTypesSelect,
    PackagingTypeSelect,
    EquipmentsSelect,
  },
  emits: ["removeElement:index"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const form = ref(props.equipment);

    return {
      form,
    };
  },
};
