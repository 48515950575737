
import { onMounted, ref, watch } from "vue";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";

export default {
  name: "ModeSelect",
  props: {
    mode_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:mode_id"],

  setup(props) {
    const { t } = useI18n();
    const data = ref([
      "imaritimefcl",
      "imaritimelcl",
      "imaritimeroro",
      "imaritimebreakbulk",
      "iaerial",
      "igroupterrestrial",
      "icompleteterrestrial",
    ]);
    const inputMode = ref({
      loading: false,
      options: data.value,
      list: data.value,
    });

    const isModeRequired = (value) => {
      if (!value) {
        return t("rmodeid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "mode",
      isModeRequired
    );

    watch(
      () => props.mode_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.mode_id;
      }, 500);
    });

    return {
      element_id,
      inputMode,
      errorMessage,
    };
  },
};
