
import { onMounted, ref, watch } from "vue";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";

export default {
  name: "FlowSelect",
  props: {
    flow_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:flow_id", "changeFlow"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const data = ref([
      {
        label: "iimportflow",
        value: "i",
      },
      {
        label: "iexportflow",
        value: "e",
      },
      {
        label: "icrosstrade",
        value: "c",
      },
    ]);
    const inputFlow = ref({
      loading: false,
      options: data.value,
      list: data.value,
    });

    const isFlowRequired = (value) => {
      if (!value) {
        return t("rmodeid");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "flow",
      isFlowRequired
    );

    watch(
      () => props.flow_id,
      (first) => {
        element_id.value = first;
        const selected = inputFlow.value.list.find((x) => x.value == first);
        emit("changeFlow", selected?.label);
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.flow_id;
      }, 500);
    });

    return {
      element_id,
      inputFlow,
      errorMessage,
    };
  },
};
