
import { onMounted, ref, watch } from "vue";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";

export default {
  name: "MerchandiseTypeSelect",
  props: {
    item_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:item_id"],

  setup(props) {
    const { t } = useI18n();
    const data = ref(["general", "dangerous", "perishable"]);
    const inputElements = ref({
      loading: false,
      options: data.value,
      list: data.value,
    });

    const isItemRequired = (value) => {
      if (!value) {
        return t("rmerchandisetype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "merchandise-type",
      isItemRequired
    );

    watch(
      () => props.item_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.item_id;
      }, 500);
    });

    return {
      element_id,
      inputElements,
      errorMessage,
    };
  },
};
