
import { Form, Field, ErrorMessage } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "AddRegularLine",
  components: {
    Field,
  },
  props: {
    forwarder: {
      type: Boolean,
      default: false,
    },
    merchandise_id: {
      type: String,
    },
    unit: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  emits: ["removeElement:index"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const form = ref(props.unit);

    return {
      form,
    };
  },
};
